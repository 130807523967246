<template>
  <router-view/>
</template>

<script lang="ts">
export default {
  name: 'App',
  watch: {
    $route: {
      immediate: true,
      handler: function (to: any, from: any) {
        document.title = to.meta.title || 'Some Default Title'
      }
    }
  }
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
body {
  background: linear-gradient(#121212, #232323);
  margin: 0;
}
#app {
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
